<template>
  <el-container>
    <el-header class="menu">
      <el-row>
        <el-col :span="8">
          <img alt="" src="./assets/image/logo.png" />
        </el-col>
        <!-- <el-col :span="7"
          ><span class="title">两岸数字文献AI识别加工平台</span></el-col
        > -->
        <el-col :span="6"> &nbsp; </el-col>
        <el-col :span="10">
          <div class="user-info">
            <div @click.prevent="userShow">
              <span>{{
                UserInfo && UserInfo.realName
                  ? UserInfo.realName
                  : UserInfo.userName
                  ? UserInfo.userName
                  : ""
              }}</span>
              <span class="el-icon-s-custom" style="padding-left: 10px"></span>
              <span class="el-icon-caret-bottom"></span>
            </div>
            <div
              class="pwd-cle"
              :style="userinfoShow ? 'height:106px' : 'height:0px'"
              ref="userInfo"
            >
              <div class="pwd el-icon-s-tools" @click="editpwd">
                <span class="user-text">修改密码</span>
              </div>
              <div class="cle el-icon-setting" @click.stop="onRecharge">
                <span class="iconfont user-text">充值记录</span>
              </div>
              <div class="cle el-icon-setting pwd" @click.stop="loginout">
                <span class="iconfont user-text">注销</span>
              </div>
            </div>
          </div>
          <!-- 修改用户密码弹出层 -->
          <el-dialog
            title="修改密码"
            :close-on-click-modal="false"
            :visible.sync="dialogFormVisible"
          >
            <el-form :model="editInfo" ref="editInfo" :rules="rules">
              <el-form-item
                label="旧密码"
                prop="oldPassword"
                :label-width="formLabelWidth"
              >
                <el-input
                  show-password
                  v-model="editInfo.oldPassword"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="新密码"
                prop="newPassword"
                :label-width="formLabelWidth"
              >
                <el-input
                  show-password
                  v-model="editInfo.newPassword"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="确认密码"
                prop="confirmNewPassword"
                :label-width="formLabelWidth"
              >
                <el-input
                  show-password
                  v-model="editInfo.confirmNewPassword"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div class="dialog-footer" style="text-align: right">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ConfirmSubmit('editInfo')"
                    >确 定</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </el-dialog>
          <!-- 充值记录 -->
          <recharge-record
            v-if="isRechargeCecordDialogShow"
            :is-show.sync="isRechargeCecordDialogShow"
          />
        </el-col>
      </el-row>
    </el-header>
    <el-main
      v-bind:style="'height:' + (bodyHeight - 120 - 6) + 'px;overflow: hidden;'"
    >
      <!-- <el-main style="height: 100%; background-color: red;"> -->
      <el-card class="card" style="height: 100%; overflow: hidden">
        <el-tabs v-model="activeName" @tab-click="handleSelect">
          <el-tab-pane label="我要识别" name="home"></el-tab-pane>
          <el-tab-pane
            label="我的识别"
            name="identifyPross"
            ref="identifyPross"
          ></el-tab-pane>
          <el-tab-pane label="识别结果" name="myIdentify"> </el-tab-pane>
          <el-tab-pane v-if="isIPManageShow" label="IP管理" name="IPManage">
          </el-tab-pane>
          <el-tab-pane
            v-if="isIPManageShow"
            label="识别明细"
            name="IdentDetails"
          >
          </el-tab-pane>
          <el-tab-pane v-if="isIPManageShow" label="用户管理" name="userList">
          </el-tab-pane>
        </el-tabs>
        <router-view @identifyProssClick="identifyProssClick"></router-view>
      </el-card>
    </el-main>
    <el-footer>
      <span>福建两岸信息技术有限公司</span>
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >闽ICP备17021731号-9</a
      >
    </el-footer>
  </el-container>
</template>
<script>
import HelloWorld from "./views/Welcome.vue";
import RechargeRecord from "@/components/RechargeRecord";

export default {
  name: "App",
  components: {
    RechargeRecord,
    HelloWorld,
  },
  data() {
    return {
      isIPManageShow: false,
      isRechargeCecordDialogShow: false, // 充值记录 dialog
      activeName: "home",
      UserInfo: {},
      screenWidth: document.body.offsetWidth,
      screenHeight: document.documentElement.clientHeight,
      userinfoShow: false,
      userInfoDialogFormVisible: false,
      //修改用户密码
      editInfo: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        confirmNewPassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
        ],
      },
      formLabelWidth: "120px",
      dialogFormVisible: false,
      Menulist: [], //菜单列表
      navpath: "", //当前激活的路由
    };
  },
  created() {
    this.getLoginUserInfo();
    this.activeName = this.$router.currentRoute.path.replace("/", "");
    if (this.$router.currentRoute.fullPath == "/") this.$router.push("/home");
  },
  computed: {
    bodyHeight() {
      console.log(this.screenHeight, "this.screenHeight");
      return this.screenHeight;
    },
  },
  methods: {
    identifyProssClick() {
      this.$router.push("identifyPross");
      this.activeName = "identifyPross";
    },
    // 展示充值记录
    onRecharge() {
      this.isRechargeCecordDialogShow = true;
      this.userinfoShow = false;
    },
    handleSelect(tab, event) {
      console.log(tab.name);
      this.$router.push(tab.name);
    },
    getLoginUserInfo() {
      this.$request({
        url: "/api/userrole/user/login-user",
        method: "GET",
        async: false,
      })
        .then((res) => {
          this.UserInfo = res;
          this.isIPManageShow = res.roleInfoDtos.some(
            (v) => v.roleTypeInfo === 3 || v.roleTypeInfo === 4
          );
          this.$store.commit("setUserInfo", res);
        })
        .catch((e) => {});
    },
    //获取用户资料
    getUserInfo() {
      this.userInfoDialogFormVisible = true;
      this.userinfoShow = false;
    },
    //用户资料弹出层
    userShow() {
      this.userinfoShow = !this.userinfoShow;
    },
    //修改用户资料
    editUserInfo() {
      this.$request({
        url: "/api/userrole/user",
        method: "POST",
        data: JSON.stringify(this.UserInfo),
      })
        .then((res) => {
          this.$cookies.get("USER", this.UserInfo);
          this.userInfoDialogFormVisible = false;
          return this.$message.success("编辑成功");
        })
        .catch((e) => {
          this.UserInfo = this.$cookies.get("USER");
          //return this.$message.error("服务器异常~");
        });
    },
    //修改密码弹窗
    editpwd() {
      this.dialogFormVisible = true;
      this.userinfoShow = false;
    },
    //修改密码
    ConfirmSubmit() {
      this.$refs.editInfo.validate((valid) => {
        if (valid) {
          if (this.editInfo.confirmNewPassword != this.editInfo.newPassword) {
            return this.$message.error("两次密码不一致");
          }
          this.loading = true;
          this.$request({
            url: "/api/userrole/user/update-password",
            method: "POST",
            data: JSON.stringify(this.editInfo),
          })
            .then((res) => {
              this.editInfo = {
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: "",
              };
              this.dialogFormVisible = false;
              return this.$message.success("密码修改成功,请重新登录");
            })
            .catch((e) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    //注销登录
    loginout() {
      this.$confirm("此操作将注销登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request({
            url: "/api/userrole/user/login-out",
            method: "POST",
          })
            .then((res) => {
              this.$cookies.remove("USER");
              this.$cookies.remove("TOKEN");
              window.location.href = "#/login";
              //  window.event.returnValue = false;
              //  return this.$message.success("已注销登录");
            })
            .catch((e) => {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
  mounted: function () {
    const that = this;
    // 自适应布局
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.offsetWidth;
        that.screenHeight = document.documentElement.clientHeight;
      })();
    };
  },
};
</script>

<style lang="less" scoped>
@import url("~@assets/css/basecss.css");
@import url("~@assets/css/index.css");
@import url("~@assets/css/iconfont.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.menu {
  background: url("../src/assets/image/bg.png") no-repeat !important;
  height: 66px !important;
}
.el-header,
.el-footer {
  box-sizing: border-box;
  height: 60px;
  // background: #e4f5ff;
  background: white;
  line-height: 60px;
}

.el-footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 30px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #f2f2f2;
  /*color: #333;
  text-align: center;*/
}

body > .el-container {
  margin-bottom: 0px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.user-info {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  z-index: 4;
  cursor: pointer;

  .pwd-cle {
    position: absolute;
    z-index: 9;
    top: 45px;
    right: -10px;
    width: 150px;
    height: 0;
    background-color: #fff;
    box-shadow: 0 0 12px #999;
    overflow: hidden;
    transition: height 0.3s;

    .user-text {
      font-size: 14px;
    }

    .pwd,
    .cle {
      display: block;
      padding: 10px 20px;

      span {
        margin-left: 10px;
      }
    }

    .pwd {
      border-bottom: 2px solid #eee;
    }
  }
}
</style>
