<template>
  <el-dialog title="充值记录" :visible="isShow" :close-on-click-modal="false" width="60%" @close="$emit('update:isShow', false)">
    <el-table
        :data="rechargeList"
        border
        :stripe="true"
        :fit="true"
        max-height="563"
    >
      <el-table-column prop="createTime" label="日期" width="180"></el-table-column>
      <el-table-column prop="id" label="订单ID" width="350"></el-table-column>
      <el-table-column prop="payMoney" label="金额"></el-table-column>
      <el-table-column prop="payType" label="订单状态" :formatter="modelFormatter"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </el-dialog>
</template>

<script>
export default {
  name: "RechargeRecord",
  props: {
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      rechargeList: [],
      PageSize: 10,
      PageIndex: 1,
      total: null
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
       const res = await this.$request({
          method: 'GET',
          url: '/api/document/user-center/order-list',
          params: {
            PageSize: this.PageSize,
            PageIndex: this.PageIndex
          }
        })
        this.rechargeList = res.items
        this.total = res.totalCount
        console.log(this.rechargeList, 'rechargeList')
        console.log(res, 'ers')
      } catch (err) {
        console.log(err.message)
      }
    },
    handleSizeChange(val) {
      this.PageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.PageIndex = val
      this.getData()
    },
    // 订单状态转化
    modelFormatter({ tradeState }) {
      return this.$store.state.dictionaries.filter(v => v.value === tradeState)[0].label
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog__header {
  padding: 0 20px 0;

  .el-dialog__headerbtn {
    top: 0;
  }
}
/deep/ .el-dialog__body {
  padding: 0 20px 20px 20px;
}
/deep/ .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  > .el-dialog__body {
    overflow: auto;
    color: #666666;
  }
}
</style>
